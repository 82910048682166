@media (min-height: 0px) and (max-height: 845px) {
  .sidebar {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: white;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 5px solid #54545438;
    .logout {
      position: absolute;
      top: 5px;
      right: 15px;
      cursor: pointer;
    }
    .logo {
      img {
        margin-top: 40px;
        width: 95px;
        height: 95px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      h2 {
        margin-top: 5px;
        font-size: 16px;
      }
      h4 {
        margin-top: 5px;
        color: rgb(26, 220, 26);
        width: 115px;
        font-size: 14px;
        font-weight: bolder;
      }
    }

    .time {
      position: absolute;
      bottom: 20px;
      font-size: 25px;
      font-weight: bolder;
    }
    .date {
      position: absolute;
      bottom: 5px;
      font-size: 19px;
      font-weight: 1;
    }
  }

  .weather-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    .temperature {
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      font-size: 20px;
      font-weight: 1;
    }
    .location {
      text-align: center;
      font-size: 15px;
      margin-left: 5px;
      margin-right: 5px;
      font-weight: bold;
    }
  }

  .weather-day {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: 10px;
    .day {
      font-size: 18px;
      font-weight: 500;
    }
    .weather {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 150px;

      p {
        font-size: 20px;
      }
    }
  }
}
@media (min-height: 846px) and (max-height: 1400px) {
  .sidebar {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: white;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 5px solid #54545438;
    .logout {
      position: absolute;
      top: 5px;
      right: 15px;
      cursor: pointer;
    }
    .logo {
      img {
        margin-top: 50px;
        width: 120px;
        height: 120px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      h2 {
        margin-top: 5px;
        font-size: 25px;
      }
      h4 {
        margin-top: 15px;
        color: rgb(26, 220, 26);
        width: 115px;
        font-size: 20px;
        font-weight: bolder;
      }
    }

    .time {
      position: absolute;
      bottom: 25px;
      font-size: 26px;
      font-weight: bolder;
    }
    .date {
      position: absolute;
      bottom: 5px;
      font-size: 20px;
      font-weight: 1;
    }
  }

  .weather-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    .temperature {
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      font-size: 20px;
      font-weight: 1;
    }
    .location {
      text-align: center;
      font-size: 22px;
      margin-left: 5px;
      margin-right: 5px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .weather-day {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: 10px;
    .day {
      font-size: 22px;
      font-weight: 500;
    }
    .weather {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 150px;

      p {
        font-size: 20px;
      }
    }
  }
}
