.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e2e2e2;
  height: 100vh;
  width: 100vw;
}

.dashboardColumn {
  flex: 1; /* Each  will take up equal space */
  padding: 20px;
  border: 1px solid #ccc;
  overflow-y: scroll;
}
.columnList {
  display: flex; /* Use flexbox to create a two-column layout */
  flex-wrap: nowrap;
  margin: 20px; /* Add some margin between columns */
}

.cardName {
  font-weight: bold;
  font-size: large;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.cardList {
  margin-top: 10px;
  
}

.header {
  flex-direction: row;
  align-items: center;
}

.card {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  background-image: linear-gradient(to top, rgb(176, 174, 174), rgb(176, 174, 174));
  background-size: 100% 50px;
  background-repeat: no-repeat;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.dutycard {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.profilePhoto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

.matching-card {
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
  margin: 5px 0;
  width: 90%; /* Set a fixed width for the cards */
  display: flex; /* Use flexbox for equal height cards */
  flex-direction: column; /* Stack card content vertically */
  justify-content: space-between; /* Space content evenly vertically */
  min-height: 80px; /* Set a minimum height for the cards */
  border-radius: 10px;
  background-image: linear-gradient(to top, rgb(176, 174, 174), rgb(176, 174, 174)); /* Background gradient with light gray at the top */
  background-size: 100% 40px; /* Adjust the height of the top section */
  background-repeat: no-repeat; /* Prevent repetition of the gradient */
}

.Icon {
  color: rgb(49, 49, 216);
}

.NameText {
  color: rgb(49, 49, 216);
}

.NameTextmemo {
  color: rgb(49, 49, 216); 
  margin-left: 12px;
  align-self: center;
}
