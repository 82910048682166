/* ScrollingText.css */
.scrolling-text-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    bottom: 10px;
    background-color: yellow;
    font-size: 33px;
  }
  
  .scrolling-text {
    display: inline-block;
    animation: scrollText 40s linear infinite;
  }
  
  @keyframes scrollText {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(-100%)
    }
  }