/* Dark mode styles */
.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .dashboard {
  background-color: #2d2d2d;
}

.dark-mode .card {
  background-color: #333333;
  border: 1px solid #555555;
  background-image: linear-gradient(to top, #1a1a1a, #1a1a1a);
}

.dark-mode .dutycard {
  background-color: #333333;
  border: 1px solid #555555;
}

.dark-mode .profilePhoto {
  border: 2px solid #ffffff;
}

.dark-mode .matching-card {
  background-color: #2d2d2d;
  border: 1px solid #555555;
  background-image: linear-gradient(to top, #1a1a1a, #1a1a1a);
}

.dark-mode .Icon {
  color: #3162d8;
}

.dark-mode .NameText,
.dark-mode .NameTextmemo {
  color: #3162d8;
}
